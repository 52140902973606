/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */
'use strict';

import PropTypes from 'prop-types';
import './../stylesheets/button-group.less';

const ButtonGroup = (props) => {
  const classNames = ['btn-group'];

  if (props.block) {
    classNames.push('btn-group-block');
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')}>{props.children}</div>
  );
};

ButtonGroup.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default ButtonGroup;
